import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
} from "@material-ui/core";
import ConnectWalletModal from "src/component/ConnectWalletModal";
import SwipeableTemporaryDrawer from "./RightDrawer";
import { AuthContext } from "src/context/Auth";
import { sortAddress } from "src/utils";
import { MdDashboard } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import FirstTimeReferralModal from "src/component/FirstTimeReferralModal";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  toolbar: {
    height: 70,
  },
  logo: {
    marginRight: theme.spacing(2),
  },

  desktopDrawer: {
    position: "absolute",
    right: 80,
    top: 30,
    width: "100%",
    // background: theme.palette.primary.main,
    height: 266,
    [theme.breakpoints.down("sm")]: {
      width: 600,
      right: 0,
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
      right: 0,
    },
  },
  buttonBoxes: {
    display: "flex",
    alignItems: "center",
    marginRight: "26px",
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();
  const history = useNavigate();
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (auth.userData.refferalCodeCheck && auth.account) {
      auth.setOpenReferralModal(true);
    }
  }, [auth.userData.refferalCodeCheck, auth.account]);

  return (
    <>
      <Box flexGrow={1} />
      <Box className={classes.buttonBoxes}>
        <Box style={{ marginRight: "8px" }}>
          <SwipeableTemporaryDrawer />
        </Box>
        {auth.ownerAddress === auth.account && (
          <IconButton
            onClick={() => {
              localStorage.setItem("ADMIN", true);
              history("/admin-dashboard");
            }}
            mr={2}
            style={{
              background: "#f4a91b",
              color: "#fff",
              padding: "4px",
              marginRight: "10px",
            }}
          >
            <MdDashboard />
          </IconButton>
        )}
        <ConnectWalletModal />
      </Box>

      <FirstTimeReferralModal
        handleCloseModal={() => auth.setOpenReferralModal(false)}
        openModal={auth.openReferralModal}
        auth={auth}
      />
    </>
  );
}
