import React from "react";
import { RouterProvider } from "react-router-dom";
import router from "src/router";
import AuthContext from "src/context/Auth";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from "./theme";
import { Toaster } from "react-hot-toast";
import "@rainbow-me/rainbowkit/styles.css";
import {
  RainbowKitProvider,
  darkTheme,
  connectorsForWallets,
} from "@rainbow-me/rainbowkit";
import { bscTestnet, bsc } from "wagmi/chains";
import {
  injectedWallet,
  metaMaskWallet,
  trustWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { baseurl } from "./ApiConfig/ApiConfig";

const projectId = "f747aa4eed391eccda4619d16b1ba493";

function App() {
  const theme = createTheme();
  const checkUrl = baseurl.includes("https://node.betsbyheart.com");
  const checkNetWork = checkUrl ? bsc : bscTestnet;
  //rainbowkit and wagmi connectors implementation for establishing web3 connectivity
  const { chains, publicClient, webSocketPublicClient } = configureChains(
    [checkNetWork],
    [publicProvider()]
  );
  const connectors = connectorsForWallets([
    // ...wallets,
    {
      groupName: "betbyheart",
      wallets: [
        injectedWallet({ projectId, chains }),
        metaMaskWallet({ projectId, chains }),
        // walletConnectWallet({ projectId, chains }),
      ],
    },
  ]);

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
    webSocketPublicClient,
  });
  return (
    <div className="bg-primary w-full overflow-hidden">
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider
          chains={chains}
          coolMode
          modalSize="compact"
          theme={darkTheme({
            accentColor: "#ffca64",
            accentColorForeground: "#fff",
            borderRadius: "small",
            fontStack: "system",
            overlayBlur: "small",
            fontWeight: "500px",
          })}
        >
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Toaster
                position="bottom-center"
                autoClose={3000}
                reverseOrder={false}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                // theme="light"
                theme={theme.palette.type}
              />
              <AuthContext>
                <RouterProvider router={router} />
              </AuthContext>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </RainbowKitProvider>
      </WagmiConfig>
    </div>
  );
}

export default App;
