import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, matchPath, useNavigate } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Typography,
  Divider,
  IconButton,
  Grid,
  Button,
} from "@material-ui/core";
import Logo from "src/component/Logo";
import NavItem from "./NavItem";
import { FaDiscord, FaTelegramPlane, FaTwitter, FaUser } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { AuthContext } from "src/context/Auth";
import ContactUsModal from "src/component/ContactUsModal";
import {
  AiFillInstagram,
  AiFillPhone,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import { toast } from "react-hot-toast";

const sections = [
  {
    items: [
      {
        title: "Home",
        href: "/",
        img: "images/sideicon.png",
        title1: "Welcome",
      },
      // {
      //   title: "Poker",
      //   href: "/transaction",
      //   img: "images/sideicon_1.png",
      //   title1: "card game",
      // },

      // {
      //   title: "Black Jack",
      //   href: "/black-jack",
      //   img: "images/sideicon_2.png",
      //   title1: "card game",
      // },
      {
        title: "Lottery",
        href: "/lottery",
        img: "images/sideicon_3.png",
        title1: "luck at guessing",
      },
      {
        title: "Derby Race",
        href: "/derby",
        img: "images/sideicon_4.png",
        title1: "Racing game",
      },
      {
        title: "H2H Betting",
        href: "/h2h",
        img: "images/sideicon_5.png",
        title1: "Fortnite quiz",
      },
    ],
  },
];
const AdminSection = [
  {
    items: [
      {
        title: "Dashboard",
        href: "/admin-dashboard",
        img: "images/sideicon.png",
      },
      // {
      //   title: "H2H Management",
      //   href: "/h2h-management",
      //   img: "images/sideicon_5.png",
      // },
      {
        title: "Static Content Management",
        href: "/static-management",
        img: "images/sideicon_5.png",
      },
      {
        title: "FAQ's Management",
        href: "/faq-management",
        img: "images/sideicon_5.png",
      },
      {
        title: "Lottery Management",
        href: "/lottery-admin",
        img: "images/lotteryicon.png",
      },
      // {
      //   title: "Derby Management",
      //   href: "/derby-admin",
      //   img: "images/horseicon.png",
      // },
    ],
  },
];

const useStyles = makeStyles((theme) => ({
  referal: {
    padding: "6px",
    // backgroundSize: "cover",
    // backgroundImage: "url(images/referal.png)",
    background:
      "linear-gradient(92.79deg, #0012ff -32.2%, #0017ff 5.51%, #2a8ccac9 54.01%, #2a96ca 110.93%)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    margin: "0 20px 8px",
    borderRadius: "10px",
    cursor: "pointer",
    "& button": {
      color: "#fff !important",
      textTransform: "uppercase",
      fontSize: "16px",
      fontWeight: "500",
    },
  },
  mobileDrawer: {
    width: 270,
  },
  desktopDrawer: {
    width: 270,
    top: 0,
    height: "100%",
    background: "#111",
    backdropFilter: "blur(3.22162px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  subSideBar: {
    padding: "0px 18px",
    "& h5": {
      fontSize: "14px",
      cursor: "pointer",
    },
    "& svg": {
      color: "#9d7247",
      fontSize: "18px",
    },
    "& .bgProfileBox": {
      background: "rgba(32, 32, 32, 1)",
      borderRadius: "10px",
      padding: "25px",
      [theme.breakpoints.down("xs")]: {
        padding: "4px 7px 4px 5px",
        background: "rgba(32, 32, 32, 1)",
        borderRadius: "10px",
        "& img": {
          width: "auto",
          maxWidth: "145px",
        },
      },
      "& h4": {
        fontSize: "12px",
        fontWeight: "300",
        marginBottom: "8px",
      },
    },
    "& .seeBox": {
      background: "#dac7c7",
      width: "19px",
      height: "19px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      marginLeft: "3px",
      cursor: "pointer",
      "& svg": {
        color: "rgba(30, 0, 12, 0.87)",
        fontSize: "13px",
      },
    },
  },
  rightImg: {
    // width: "calc(100% + 60px)",
    position: "absolute",
    left: "50%",
    top: "14px",
    maxWidth: "fit-content",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      top: "228px",
      display: "none",
    },
  },
  TopBoxes: {
    padding: "16px 18px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 18px",
    },
    "& h5": {
      fontSize: "14px",
      cursor: "pointer",
      "@media(max-width:767px)": {
        fontSize: "14px !important",
      },
    },
    "& .randomStarBox": {
      backgroundColor: "rgba(255, 255, 255, 0.03)",
      width: "100%",
      maxWidth: "88px",
      padding: "5px 4px",
      borderRadius: "50px",
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      "& p": {
        fontSize: "10px",
        "@media(max-width:767px)": {
          fontSize: "10px !important",
        },
      },
    },
  },
  positionClx: {
    width: "100%",
    position: "absolute",
    bottom: 16,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useNavigate();
  const auth = useContext(AuthContext);
  const [contactModal, setContactModal] = useState(false);
  const checkUserType = localStorage.getItem("ADMIN") ? AdminSection : sections;

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box padding={0} align="center">
        <Logo
          onClick={() => {
            localStorage.getItem("ADMIN") && localStorage.removeItem("ADMIN");
            history("/");
          }}
          className="logoDrawerBox"
          alt="Logo"
        />
      </Box>
      <Box mt={1}>
        <img src="images/lineborder.png" alt="borderImage" />
      </Box>

      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box mb={2}>
          {!localStorage.getItem("ADMIN") && (
            <Box className={classes.TopBoxes}>
              <Box display="flex" alignItems="center">
                <img
                  src="images/royal.png"
                  alt=""
                  style={{ width: "100%", maxWidth: "18px" }}
                />
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    marginLeft: "3px",
                  }}
                >
                  Royal Games
                </Typography>
              </Box>

              <Box className="randomStarBox">
                <img
                  src="images/star.png"
                  alt=""
                  style={{ width: "100%", maxWidth: "13px" }}
                />
                <Typography variant="body2">Random</Typography>
              </Box>
            </Box>
          )}

          {checkUserType?.map((section, i) => (
            <List
              className="sideMenuBox"
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              <List disablePadding>
                {section?.items.map((item) => (
                  <NavItem
                    href={item.href}
                    key={item.title}
                    title={item.title}
                    img={item.img}
                    title1={item.title1}
                  />
                ))}
              </List>
            </List>
          ))}

          {!localStorage.getItem("ADMIN") && (
            <Box className={classes.positionClx}>
              <Box
                align="center"
                className={classes.referal}
                onClick={() => history("/referral")}
              >
                <Button>Referral</Button>
              </Box>
              <Box className={classes.subSideBar}>
                <Box className="bgProfileBox" align="center">
                  <Hidden xsDown>
                    <img src="images/join.png" alt="joint" />
                  </Hidden>
                  <Box className="displayStart socialresponBox">
                    <IconButton
                      href="https://t.me/+IK3LR5TPVhs1N2Fl"
                      target="_blank"
                      className="socialButton"
                    >
                      <FaTelegramPlane />
                    </IconButton>
                    <IconButton
                      href="https://instagram.com/betbyheart.official?igshid=MzMyNGUyNmU2YQ=="
                      target="_blank"
                      className="socialButton"
                    >
                      <AiFillInstagram />
                    </IconButton>
                    <IconButton
                      href="https://www.whatsapp.com/channel/0029VaAbWMT0LKZ8fa1mev2j"
                      target="_blank"
                      className="socialButton"
                    >
                      <AiOutlineWhatsApp />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              <Box
                mt={2}
                style={{ cursor: "pointer" }}
                className="displayCenter"
                onClick={() => setContactModal(true)}
              >
                <AiFillPhone style={{ color: "orange" }} />
                &nbsp;
                <Typography variant="body2" className="contactusText">
                  Contact Us
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
      <ContactUsModal openModal={contactModal} setOpenModal={setContactModal} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Box>
          <Drawer
            anchor="left"
            classes={{ paper: classes.desktopDrawer }}
            open
            variant="persistent"
          >
            {content}
          </Drawer>
        </Box>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
