import React, { useEffect } from "react";
import {
  makeStyles,
  Dialog,
  Box,
  Grid,
  Typography,
  IconButton,
  Tooltip,
  Hidden,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { SlQuestion } from "react-icons/sl";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ConnectButton,
  RainbowKitProvider,
  lightTheme,
  useChainModal,
  useConnectModal,
} from "@rainbow-me/rainbowkit";
import { getNetwork } from "@wagmi/core";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    padding: "2.5px",
    backgroundImage:
      "linear-gradient(108.56deg, #EA9F28 16.26%, #CD851D 38.33%, #D29E2E 60.15%, #B37413 79.49%, #F1A72C 123.99%)",
    "& .root": {
      padding: "30px",
      position: "relative",
      borderRadius: "5px",
      backgroundColor: "#0A0807",
      overflow: "auto",
      paddingTop: "50px",
      paddingBottom: "40px",

      "& h4": {
        fontWeight: "600",
        color: "rgba(255, 255, 255, 0.87)",
        marginBottom: "30px",
        marginTop: "20px",
      },
    },
    "& .boxColor": {
      borderRadius: "10px",
      border: "1px solid rgb(87 77 77)",
      background: "#22201f",
      padding: "34px 0px",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      "& img": {
        width: "100%",
        maxWidth: "46px",
        margin: "0 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      "& p": {
        marginTop: "16px",
      },
    },
    "& .backBox": {
      position: "absolute",
      content: " ",
      borderRadius: "10px",
      top: "0",
      left: "0",
      background: "#0d0d0d",
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .imgBox": {
      width: "100%",
      maxWidth: "327px",
      [theme.breakpoints.down("xs")]: {
        margin: "0 auto",
        display: "flex",
        justifyContent: "center",
      },
    },
  },
  closeIcon: {
    position: "absolute",
    right: "0px",
    top: "0px",
    "& svg": {
      color: "#FFFFFF",
    },
  },
  dialogWidthSm: {
    maxWidth: "820px !important",
  },
  howToBox: {
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
    cursor: "pointer",
    "& svg": {
      color: "#a89f9f",
      cursor: "pointer",
    },
    "& h6": {
      color: "#a89f9f",
      marginLeft: "10px",
    },
  },
  backdrop: {
    backdropFilter: "blur(5px)",
  },
  MainToolClx: {
    "& img": {
      height: "150px",
      width: "100%",
    },
  },
}));

function ConnectWalletModal() {
  const classes = useStyles();
  const location = useLocation();
  const { chain, chains } = getNetwork();
  const history = useNavigate();

  useEffect(() => {
    if (location) {
      window.localStorage.setItem(
        "referralCode",
        location && location?.search?.split("?")[1]
      );
    }
  }, [location]);

  return (
    <RainbowKitProvider
      chains={chains}
      coolMode
      modalSize="compact"
      theme={lightTheme({
        accentColor: "linear-gradient(164deg, #FFCA64 0%, #CA772A 100%)",
        accentColorForeground: "#fff",
        borderRadius: "small",
        fontStack: "system",
        overlayBlur: "small",
        fontWeight: "500px",
        // borderRadius: "50px",
      })}
    >
      <ConnectButton showBalance={false} />
    </RainbowKitProvider>
  );
}

export default ConnectWalletModal;
