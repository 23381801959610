/* eslint-disable no-use-before-define */
import React, { useState, useContext } from "react";
import { matchPath } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { Box, List, makeStyles, Avatar, Button } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import NavItem from "src/layouts/DashboardLayout/NavBar/NavItem";
import { useNavigate } from "react-router-dom";
import HomeIcon from "src/Icons/HomeIcon";
import SeetingIcon from "src/Icons/SeetingIcon";
import DisconnectIcon from "src/Icons/DisconnectIcon";
import { AuthContext } from "src/context/Auth";
import ConfirmModal from "src/component/ConfirmModal";
const sections = [
  {
    title: "Update Profile",
    href: "/create-profile",
    icon: HomeIcon,
  },
  {
    title: "Setting",
    href: "/",
    icon: SeetingIcon,
  },

  // {
  //   title: "Disconnect",
  //   href: "/",
  //   icon: DisconnectIcon,
  // },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item?.title + depth;

  if (item?.items) {
    const open = matchPath(pathname, {
      path: item?.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item?.icon}
        info={item?.info}
        key={key}
        open={Boolean(open)}
        title={item?.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item?.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item?.href}
        icon={item?.icon}
        info={item?.info}
        key={key}
        title={item?.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    position: "absolute",
    right: 0,
    top: 30,
    width: 317,
    // background: theme.palette.primary.main,
  },
  avatar: {
    cursor: "pointer",
    width: 32,
    height: 32,

    // "@media (max-width: 767px)": {
    //   width: "30px",
    //   height: "30px",
    // },
  },
  avatarBig: {
    cursor: "pointer",
    width: 150,
    height: 150,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  profileButton: {
    background: "rgba(255, 255, 255, 0.1)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    padding: "15px",
    margin: "8px",
    "& p": {
      fontWeight: "600",
      color: "#fff",
    },
  },
  button: {
    padding: "9px 8px",
    borderRadius: "0px",
    borderBottom: "0.5px solid rgba(255, 255, 255, 0.2)",
    fontWeight: "400",
    fontSize: "18px",
    color: "rgba(255, 255, 255, 0.6)",
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },
  iconbutton: {
    color: "#fff",
    background: "rgba(255, 255, 255, 0.15)",
    borderRadius: "14px",
    padding: "8px",
    fontSize: "20px",
    marginRight: "10px",
  },
  profileBox: {
    backgroundColor: "rgba(25, 23, 23, 1)",
    borderRadius: "10px",
    padding: "6px 0px 2px",
  },
}));

const NavBar = () => {
  const classes = useStyles();
  const history = useNavigate();
  const auth = useContext(AuthContext);
  const [rightBar, setRightBar] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box className={classes.profileBox}>
        <PerfectScrollbar
          options={{ suppressScrollX: true }}
          className="walletbox"
        >
          <Box pb={2} px={1}>
            {sections
              ?.filter((value, i) =>
                value?.title === "Disconnect" ? auth?.account : value
              )
              ?.map((section, i) => {
                const Icon = section.icon;
                return (
                  <Button
                    fullWidth
                    className={classes.button}
                    style={{
                      justifyContent: "left",
                      textTransform: "capitalize",
                    }}
                    key={i}
                    onClick={() => {
                      section.title === "Disconnect"
                        ? (() => {
                            setRightBar(false);
                            setConfirmModal(true);
                          })()
                        : history(section.href);
                    }}
                  >
                    <Icon className={classes.iconbutton} /> &nbsp;&nbsp;
                    {section.title}
                  </Button>
                );
              })}
          </Box>
        </PerfectScrollbar>
      </Box>
    </Box>
  );

  return (
    <>
      <Avatar
        src={auth?.userData?.profilePic}
        className={classes.avatar}
        onClick={() =>
          localStorage.getItem("ADMIN")
            ? (() => {
                localStorage.removeItem("ADMIN");
                history("/");
              })()
            : setRightBar(!rightBar)
        }
      />
      <Dialog
        classes={{ paper: classes.desktopDrawer }}
        open={rightBar}
        onClose={() => {
          setRightBar(false);
        }}
      >
        {content}
      </Dialog>

      <ConfirmModal
        handleClose={() => setConfirmModal(false)}
        confirmModal={confirmModal}
        text="Are you sure, you want to disconnect?"
        handleSubmit={() => {
          auth.disconnectWalletHandler();
          setConfirmModal(false);
        }}
        isLoading={false}
      />
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
