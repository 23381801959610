// export const baseurl = "http://172.16.1.224:1992"; ////Vishnu local URL
// export const baseurlSocket = "ws://172.16.1.224:2008";
// export const horseRaceURl = "http://172.16.10.6:2005/horseRacing/index.html"; ///local

//staging///
export const baseurl = "https://node-ecosystem.mobiloitte.io"; //staging URL
export const baseurlSocket = "wss://socket-ecosystem.mobiloitte.io";
export const horseRaceURl = "https://unitygame-ecosystem.mobiloitte.io";

// master //
// export const baseurl = "https://node.betsbyheart.com"; //master URL
// export const baseurlSocket = "wss://socket.bbhtesting.com";
// export const horseRaceURl = "https://webgl.bbhtesting.com";

// master // ---- testing only derby
// export const baseurl = "https://nodenew.bbhtesting.com"; //master URL
// export const baseurlSocket = "wss://socketnew.bbhtesting.com";
// export const horseRaceURl = "https://webgl.bbhtesting.com";

let url = `${baseurl}/api/v1`;
let lottery = `${url}/lottery`;

const ApiConfig = {
  connectWallet: `${url}/user/connectWallet`,
  getProfile: `${url}/user/getProfile`,
  editProfile: `${url}/user/editProfile`,
  addReffferalCode: `${url}/user/addReffferalCode`,

  //Admin Category
  addCategory: `${url}/h2h/addCategory`,
  getCategory: `${url}/h2h/getCategory`,
  deleteCategory: `${url}/h2h/deleteCategory`,

  //Admin Fee
  getAdminFee: `${url}/admin/getAdminFee`,
  editAdminFee: `${url}/admin/editAdminFee`,
  topWinner: `${url}/admin/topWinner`,
  popularPlayer: `${url}/admin/popularPlayer`,

  //Admin Question
  questionLIst: `${url}/h2h/questionLIst`,
  addQuestion: `${url}/h2h/addQuestion`,
  deleteQuestion: `${url}/h2h/deleteQuestion`,
  checkGameStatus: `${url}/h2h/checkGameStatus`,

  ///User h2h
  liveGame: `${url}/h2h/liveGame`,
  betPlace: `${url}/h2h/betPlace`,
  betPlaceV1: `${url}/h2h/betPlaceV1`,
  distributeAmount: `${url}/h2h/distributeAmount`,

  //LOTTERY//
  createRoom: `${lottery}/createRoom`,
  buyTickets: `${lottery}/buyTickets`,
  onGoingEvent: `${lottery}/onGoingEvent`,
  createLottory: `${lottery}/createLottory`,
  listLottory: `${lottery}/listLottory`,
  winningAmount: `${lottery}/winningAmount`,
  refferalHistory: `${lottery}/refferalHistory`,
  amountClaim: `${lottery}/amountClaim`,

  //referral
  directIndirectUserList: `${url}/user/directIndirectUserList`,

  //derby game
  scheduleEvent: `${url}/derby/scheduleEvent`,
  derbyhistory: `${url}/derby/history`,
  eventLists: `${url}/derby/eventLists`,
  joinEventV2: `${url}/derby/joinEventV2`,
  leaderboards: `${url}/derby/leaderboards`,
  leaderboardList: `${url}/derby/leaderboardList`,
  winnerHorse: `${url}/derby/winnerHorse`,

  // Admin Static Content
  listStaticContent: `${url}/static/staticContentList`,
  editStaticContent: `${url}/static/editStaticContent`,
  contactUs: `${url}/contact/contactUs`,

  // Admin Faq Management
  addFaq: `${url}/static/addFAQ`,
  editFaq: `${url}/static/editFaq`,
  deleteFaq: `${url}/static/deleteFaq`,
  listFAQ: `${url}/static/listFAQ`,

  ///marketGraph///
  marketPriceHistoricalData: `${url}/h2h/marketPriceHistoricalData`,
};
export default ApiConfig;
