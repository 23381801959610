import React from "react";
import { Box, IconButton, Typography, makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { FaTelegramPlane, FaTwitter } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    width: "calc(100% - 287px)",
    borderTop: "1px solid #80808045",
    // height: "24px",
    position: "fixed",
    bottom: "0",
    zIndex: "999",
    background: "#111",
    padding: "15px",
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 21px)",
    },
    "& .privacyText": {
      fontSize: "14px",
      // color: "orange",
      // textDecoration: "underline",
      color: "rgba(255, 255, 255, 0.60)",
      cursor: "pointer",
      whiteSpace: "pre",
    },
    "& .displayStart": {
      // justifyContent: "end",
    },
    "& .displaySpacebetween": {
      flexWrap: "wrap",
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  const history = useNavigate();

  return (
    <Box className={classes.mainBox}>
      <Box className="displaySpacebetween">
        <Typography
          variant="body2"
          style={{ color: "rgba(255, 255, 255, 0.60)" }}
        >
          © 2023 Bet by heart. All Rights Reserved
        </Typography>

        <Box className="displayStart" mr={2}>
          {/* <Typography
            variant="h6"
            className="privacyText"
            onClick={() => history.push("/privacy-policy")}
          >
            Privacy Policy
          </Typography>
          <Typography
            variant="h6"
            className="privacyText"
            onClick={() => history.push("/terms-condition")}
            style={{ marginLeft: "10px" }}
          >
            Terms Condition
          </Typography> */}
          <Typography
            variant="h6"
            className="privacyText"
            onClick={() => history("/aboutUs")}
            style={{ marginLeft: "10px" }}
          >
            About Us
          </Typography>
          <Typography
            variant="h6"
            className="privacyText"
            onClick={() => history("/features")}
            style={{ marginLeft: "10px" }}
          >
            Features
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
