import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import {
  contractDecimals,
  getBalanceContract,
  lotteryContract,
} from "src/constants";
import toast from "react-hot-toast";
import lotteryABI from "src/ABI/lotteryABI.json";
import GetBalanceContractABI from "src/ABI/GetBalanceContractABI.json";
import {
  dataPostHandler,
  getParticularObjectDataHanndler,
} from "src/ApiConfig/service";
import { getOnGoingLotteryDataBlockchain } from "src/api/CurrentLotteryData";
import { useAccount } from "wagmi";
import { useEthersSigner } from "src/utils/ethersSigner";
import { getNetwork, fetchBalance } from "@wagmi/core";
import { publicClient } from "./client";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

export default function AuthProvider(props) {
  const { address: account } = useAccount();
  const { chain, chains } = getNetwork();
  const signer = useEthersSigner();
  const [userData, setUserData] = useState({});
  const [tokenBalance, settokenBalance] = useState("");
  const [ownerAddress, setOwnerAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [openReferralModal, setOpenReferralModal] = useState(false);
  const [currentLotteryData, setCurrentLotteryData] = useState({
    currentTicketValue: "",
    ticketData: "",
    allData: {},
    ticketNumber: "",
  });

  const connnectWalletApi = async (account) => {
    try {
      const response = await dataPostHandler("connectWallet", {
        walletAddress: account,
        referralCode:
          window.localStorage.getItem("referralCode") !== "undefined" &&
          window.localStorage.getItem("referralCode") !== null
            ? window.localStorage.getItem("referralCode")
            : undefined,
      });
      if (response?.data?.responseCode === 200) {
        window.localStorage.setItem("token", response?.data?.result?.token);
        setSession(response?.data?.result?.token);
        viewUserProfile();
      } else {
        toast.error(response?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.responseMessage);
    }
  };

  const viewUserProfile = async () => {
    try {
      const response = await getParticularObjectDataHanndler("getProfile");
      if (response.data.responseCode === 200) {
        setUserData(response.data.result);
      } else {
        setUserData({});
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTokenWalletBalance = async () => {
    try {
      const contract = {
        abi: lotteryABI,
        address: lotteryContract,
      };
      const ownerAcc = await publicClient.readContract({
        ...contract,
        functionName: "owner",
      });
      const balance = await publicClient.readContract({
        address: getBalanceContract,
        abi: GetBalanceContractABI,
        functionName: "balanceOf",
        args: [account],
      });
      settokenBalance(
        parseFloat(Number(balance) / Math.pow(10, contractDecimals)).toFixed(3)
      );
      setOwnerAddress(ownerAcc);
    } catch (error) {
      console.log(error);
    }
  };

  const getGlobalLotteryData = async () => {
    try {
      setIsLoading(true);
      const response = await getOnGoingLotteryDataBlockchain({
        account: account,
        page: "",
        signer,
      });
      setIsLoading(false);
      setCurrentLotteryData(response);
      setIsUpdated(!isUpdated);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // && Number(chain?.id) == Number(chains[0]?.id)
    if (account) {
      getTokenWalletBalance();
      getGlobalLotteryData();
    }
  }, [account]); // eslint-disable-line

  useEffect(() => {
    if (account) {
      connnectWalletApi(account);
    } else {
      window.localStorage.removeItem("token");
      localStorage.removeItem("ADMIN");
      localStorage.removeItem("referralCode");
    }
  }, [account]);

  let data = {
    userData,
    account,
    signer,
    chains,
    isUpdated,
    isLoading,
    tokenBalance,
    ownerAddress,
    currentLotteryData,
    openReferralModal,
    setOpenReferralModal,
    setIsLoading: (data) => setIsLoading(data),
    viewUserProfile: viewUserProfile,
    getGlobalLotteryData: getGlobalLotteryData,
    getTokenWalletBalance: getTokenWalletBalance,
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
