import { strtodec, convertBigIntToNumber } from "src/utils";
import { contractDecimals, lotteryContract } from "src/constants";
import lotteryABI from "src/ABI/lotteryABI.json";
import { publicClient } from "src/context/client";

export const getOnGoingLotteryDataBlockchain = async ({
  account,
  page,
  signer,
}) => {
  try {
    const contractCall = {
      abi: lotteryABI,
      address: lotteryContract,
    };

    const previousAmount = await publicClient.readContract({
      ...contractCall,
      functionName: "totalAmountUntillLastLottery",
    });

    const previousAm = parseInt(previousAmount);
    console.log("previousAmount----", previousAm);

    const lotteryId = await publicClient.readContract({
      ...contractCall,
      functionName: "currentLotteryId",
    });
    const lotteryid = parseInt(lotteryId);
    console.log("lotteryId----", lotteryId);

    const id = page ? page?.toString() : Number(lotteryid);

    const viewLotteryDetail = await publicClient.readContract({
      ...contractCall,
      functionName: "viewLottery",
      args: [id],
    });

    console.log("viewLotteryDetail----", viewLotteryDetail);

    const viewLotteryDetails = convertBigIntToNumber(viewLotteryDetail);

    const currentTicketNumber = await publicClient.readContract({
      ...contractCall,
      functionName: "currentTicketId",
    });

    const currentTicketNum = parseInt(currentTicketNumber);
    console.log("currentTicketNumber----", currentTicketNum);

    const checkTotalPrice = await publicClient.readContract({
      ...contractCall,
      functionName: "calculateTotalPriceForBulkTickets",
      args: [
        [viewLotteryDetails.discountDivisor?.toString()],
        [viewLotteryDetails.priceTicketInCake?.toString()],
        ["1"],
      ],
    });
    console.log("checkTotalPrice----", checkTotalPrice);

    const checkTotalPric = parseInt(checkTotalPrice);
    console.log("currentTicketNumber----", currentTicketNum);

    const priceinwei =
      Number(checkTotalPric?.toString()) / Math.pow(10, contractDecimals);

    const priceinwe = parseInt(priceinwei);
    console.log("priceinwei----", priceinwei);

    let userinfo = {};
    if (account) {
      const viewUserInfoForLottery = await publicClient.readContract({
        ...contractCall,
        functionName: "viewUserInfoForLotteryId",
        args: [account, id, 0, 100],
      });

      userinfo = convertBigIntToNumber(viewUserInfoForLottery);
    }
    return {
      currentTicketValue: currentTicketNumber,
      allData: viewLotteryDetails,
      ticketNumber: priceinwe,
      ticketData: account ? userinfo : null,
      lotteryId: Number(lotteryid),
      previousPendingAmount:
        Number(previousAm?.toString()) / Math.pow(10, contractDecimals),
    };
  } catch (error) {
    console.log(error);
  }
};
