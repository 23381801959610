import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, Collapse, ListItem, makeStyles, Box } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { toast } from "react-hot-toast";
import { AuthContext } from "src/context/Auth";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    padding: "0px 10px",
  },
  button: {
    color: "#000000",
    padding: "10px 0",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
  },
  buttonLeaf: {
    color: "rgba(255, 255, 255, 0.70)",
    width: "100%",
    margin: "2px 8px",
    fontSize: "15px",
    fontWeight: "300",
    borderRadius: "50px",
    letterSpacing: "0",
    textTransform: "none",
    justifyContent: "flex-start",
    "&.depth-0": {
      "& $title": {
        fontWeight: "400",
      },
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    color: "rgba(255, 255, 255, 0.6)",
    "& svg": {
      color: "#AC7AE4",
    },
  },
  title: {
    marginRight: "auto",
    textTransform: "uppercase",
    fontWeight: "400",
  },
  title1: {
    fontSize: "12px",
    fontWeight: "200",
    marginRight: "auto",
    textTransform: "capitalize",
  },
  active: {
    color: "#fff",
    borderRadius: "50px",
    fontSize: "18px",
    background:
      "linear-gradient(92.79deg, #FFB000 -32.2%, #FFB000 5.51%, #CA772A 54.01%, #CA772A 110.93%)",
    margin: "7px 5px",
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    icon: {
      color: "#fff",
      "& svg": {
        color: "#fff",
      },
    },
    "& $icon": {
      color: "#fff",
    },
  },
  iconCircle: {
    "& img": {
      width: "auto",
      maxWidth: "40px",
      marginRight: "13px",
      objectFit: "cover",
    },
  },
}));

const NavItem = ({
  children,
  className,
  depth,
  href,
  img,
  info: Info,
  open: openProp,
  title,
  title1,
  ...rest
}) => {
  const classes = useStyles();
  const history = useNavigate();
  const auth = useContext(AuthContext);
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;
  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }
  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          className={classes.button}
          onClick={handleToggle}
          style={{ color: "#ccc" }}
        >
          <Box className={classes.iconCircle}>
            {img && (
              <img src={img} alt={title} className={classes.icon} size="20" />
            )}
          </Box>
          <Box>
            <span className={classes.title}>{title}</span>
            {/* <span className={classes.title}>fjyki</span> */}
          </Box>

          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <Box>
      <ListItem
        className={clsx(classes.itemLeaf, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          activeClassName={classes.active}
          className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          exact
          style={style}
          onClick={() => {
            if (href === "/h2h") {
              toast.success("Coming Soon!");
            } else {
              history(`${href}`);
            }
          }}
        >
          <Box className={classes.iconCircle}>
            {img && <img src={img} alt={title} className={classes.icon} />}
          </Box>
          <Box align="left">
            <span className={classes.title}>{title}</span>
            <Box>
              <span className={classes.title1}>{title1}</span>
            </Box>
          </Box>

          {Info && <Info />}
        </Button>
      </ListItem>
    </Box>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  title1: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
